import React from 'react'
import './Classes.scss'
import { graphql } from 'gatsby'
import HeroBanner from '../../components/HeroBanner'
import CardContent from '../../components/CardContent'
import Layout from '../../components/Layout'
import { GenericWordpressToObject } from '../../util/WordpressUtil'

const Classes = ({ data }) => {
  return (
    <Layout>
      <div className="Classes">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Our Classes"
          caption="Classes to crush your goals."
        />
        <div className="container content">
          <CardContent
            items={data.allWordpressWpClass.edges.map(GenericWordpressToObject)}
            modal={true}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Classes

export const query = graphql`
  {
    allWordpressWpClass(sort: { order: ASC, fields: order }) {
      edges {
        node {
          content
          title
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
